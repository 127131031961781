.buy-wrapper {
	padding: 26px 30px;

	.arrow-down {
		margin-left: 20px;
	}

	&__name-title {
		display: none;
		color: #656c7b;
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
	}

	.btn-action__approve {
		display: inline-flex;
		gap: 6px;
		align-items: center;
		margin-right: 6px;
	}

	.btn-action__approve,
	.btn-ghost {
		width: 140px;
	}

	.tabs-wrapper {
		display: flex;
		justify-content: space-between;

		.tabs {
			display: flex;
		}
	}

	.btn-report {
		margin-top: 8px;

		a {
			font-weight: 700;
			font-size: 14px;
			line-height: 20px;
			color: #f04461;
			text-decoration: underline !important;
			cursor: pointer;
		}
	}

	.btn-filters {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		gap: 4px;
		border: 2px solid #dee6f5;
		border-radius: 6px;
		padding: 5px 8px;
		background-color: #ffffff;
		font-size: 14px;
		font-weight: 700;
		line-height: 17px;
		width: 112px;
	}

	.filters-wrapper {
		padding-bottom: 24px;
		border-bottom: 1px solid #dee6f5;

		.filter {
			padding: 20px;
			margin-top: 15px;
			background: white;
			border: 1px solid #dee6f5;
			box-sizing: border-box;
			border-radius: 8px;
		}
	}

	.confirmed-bet {
		display: flex;
		gap: 5px;
		flex-wrap: wrap;
	}

	.trades-history {
		font-weight: 700;
		font-size: 14px;
		display: flex;
		align-items: center;
		gap: 4px;
		line-height: 20px;
		color: #2d78e9;
		cursor: pointer;
	}

	.title-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.sell-approve-vin {
		margin-top: 16px;
	}
}

@media screen and (max-width: 780px) {
	.buy-wrapper {
		padding: 24px 16px;

		.title {
			margin-top: 0;
		}

		&__name-title {
			display: block;
		}

		.tabs-wrapper {
			padding: 0;
			gap: 6px;
			margin-top: 20px;

			.filters-count {
				top: -8px;
				left: 92px;
			}

			.tabs {
				gap: 4px;
				width: 100%;
				justify-content: end;
				flex-wrap: nowrap;
				margin-top: 0;

				.tab-btn {
					padding: 5px 10px !important;
					height: 32px;
					justify-content: space-between;
				}
			}
		}

		.react-bootstrap-table {
			th {
				width: 40vw;
			}
		}
	}

	.title-wrapper {
		flex-direction: column;
		gap: 8px;
		align-items: flex-start !important;

		.title {
		}
	}
}
